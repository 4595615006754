.slogan{
  max-width: 535px;
  & > div{
    font-size: 30px;
    font-weight: 300;
    color: #aa8533;
    margin-top: 60px;
    margin-bottom: 40px;
    padding-right: 30px;
    position: relative;
    &:after{
      content: "”";
      position: absolute;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      top: 0;
      right: 0;
    }
    &:before{
      content: "“";
      position: absolute;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      bottom: 0;
      left: 0;
    }
  }
}

/*responsive*/
@include respond-to('medium'){
  .slogan>div{
    font-size: 26px;
  }
}

@include respond-to('small'){
  .slogan>div{
    font-size: 22px;
  }
}

@include respond-to('extra-small'){
  .slogan>div{
    font-size: 18px;
  }
}
