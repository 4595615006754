
.wrap-newslater{
  width: 100%;
  min-height: 204px;
  border: 3px solid #aa8533;
  margin-bottom: 60px;
  padding-top: 45px;
  padding-right: 45px;
}

.newslater-txt{
  margin-bottom: 35px;
  & > p{
    font-size: 26px;
    font-weight: 300;
    color: #000;
    line-height: 1;
    text-align: center;
    & > span{
      font-size: 32px;
      font-weight: 300;
      color: #aa8533;
    }
  }
}

.newslater-form{
  input[type="text"]{
      width: 258px;
      height: 49px;
      border: 2px solid #e2d7c5;
      background-color: #fff;
      color: #000;
      font-size: 18px;
      font-weight: 300;
      padding: 6px 12px;
      &:focus{
          @include placeholder{
              opacity: 0; transition: opacity 0.3s ease;
          }
      }
      @include placeholder{
        color: #000;
        font-size: 18px;
        font-weight: 300;
        opacity: 1; transition: opacity 0.3s ease;
      }
  }
  .form-group{
     margin-left: 19px;
  }
  & textarea{
    width: 818px;
    height: 49px;
    border: 2px solid #e2d7c5;
    resize: none;
    font-size: 18px;
    font-weight: 300;
    padding-right: 10px;
    padding-top: 8px;
    vertical-align: bottom;
    margin-left: 19px;
    &:focus{
        box-shadow: none;
        @include placeholder{
            opacity: 0; transition: opacity 0.3s ease;
        }
    }
    @include placeholder{
      color: #000;
      font-size: 18px;
      font-weight: 300;
      opacity: 1; transition: opacity 0.3s ease;
    }
  }
}

.btn-and-textarea{
  margin-top: 20px;
}

.form-inline .checkbox{vertical-align: bottom;}

.wrap-checkbox{
  display: inline-block;
  vertical-align: middle;
}

.checkbox-para{
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  p{
    font-size: 16px;
    font-weight:400;
    color: #000;
    line-height: 1.3;
    margin-bottom: 0;
    text-align: initial;
  }
}

.checkbox {
  background-image:url('../images/checkbox-checked.png');
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

button[type="submit"]{
  width: 250px;
  height: 51px;
  font-size: 20px;
  font-weight: 300;
  font-family: Assistant;
  color: #fff;
  border: 0;
  position: relative;
  background: rgb(216,167,61);
  background: -moz-linear-gradient(top,  rgba(216,167,61,1) 0%, rgba(127,101,32,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(216,167,61,1) 0%,rgba(127,101,32,1) 100%);
  background: linear-gradient(to bottom,  rgba(216,167,61,1) 0%,rgba(127,101,32,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8a73d', endColorstr='#7f6520',GradientType=0 );
  @include transition(all .5s ease);
  & > i{
    font-size: 15px;
    margin-right: 5px;
    @include transition(all .4s ease);
  }
  &:hover{
    background: rgb(126,100,31);
    background: -moz-linear-gradient(top,  rgba(126,100,31,1) 0%, rgba(217,168,61,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(126,100,31,1) 0%,rgba(217,168,61,1) 100%);
    background: linear-gradient(to bottom,  rgba(126,100,31,1) 0%,rgba(217,168,61,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e641f', endColorstr='#d9a83d',GradientType=0 );
    & > i{
      transform: translateX(-5px);
      -webkit-transform: translateX(-5px);
      -moz-transform: translateX(-5px);
    }
  }
  &:focus{
    & > i{
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
  }
}

/*responsive*/

@media(max-width:1235px){
  .wrap-newslater{
    text-align: center;
    padding-bottom: 5px;
  }
  button[type=submit]{
    margin-top: 8px;

  }
}

@include respond-to('medium'){
  .wrap-newslater{
    padding: 30px 20px;
    .form-group{
      margin-bottom: 15px;
    }
  }
  .newslater-form textarea{
    width: 100%;
  }
}

@include respond-to('small'){
  .newslater-form {
    input[type=text]{
      margin: 0 auto;
    }
    textarea{
      height: 90px;
    }
  }
  .checkbox{
    margin: 0;
  }
  .wrap-checkbox{
    & > label{
      margin: 0;
      display: initial;
    }
  }
  .btn-and-textarea{
    margin-top: 10px;
  }
  .newslater-txt{
    margin-bottom: 30px;
    & > p{
      font-size: 18px;
      & > span{
        font-size: 22px;
      }
    }
  }
}

@include respond-to('extra-small'){
  .newslater-form .form-group,
  .form-inline .checkbox{
    margin-left: 0;
  }

  .checkbox .checkbox-para{
    margin-right: 0;
    & > p{
      font-size: 14px;
    }
  }

  .newslater-form input[type=text]{
    width: 100%;
  }
  button[type=submit]{
    width: 100%;
  }
}
