.project-header{
  margin-top: 130px;
  text-align: center;
  @include respond-to('small'){
    margin-top: 100px;
  }
  & > h1{
    font-size: 30px;
    font-weight: 300;
    color: #aa8533;
    margin: 0;
    @include respond-to('small'){
      font-size: 25px;
    }
  }
  & > p{
    margin: 0;
    font-size: 18px;
  }
  & > hr{
    border-top: 1px solid #e2d7c5;
    margin-top: 25px;
    margin-bottom: 40px;
  }
}

.project-btn{
  display: block;
  background-color: #aa8533;
  color: #fff;
  max-width: 130px;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px;
  margin: 10px auto;
  text-align: center;
  @include border-radius(50px);
  &:hover{
    color: #fff;
  }
  &:active{
    @include btn-effect-active;
  }
}
