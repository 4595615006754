.custom-form{
  .checkbox{
    margin-left:0px;
    margin-top:-11px;
  }
  .fileupload{
    position: relative;
    .note{
      position: absolute;
      top: 4px;
      right: -180px;
      color: #f9ed00;
      text-align: left;
      @include respond-to('small') {
        position: relative;
        right:0;
      }
    }
  }
  .btn-file {
    position: relative;
    overflow: hidden;
    border: 1px solid #e2d6c6;
    width: 100%;
    height: 44px;
    border-radius: 0px;
    color: #000;
    font-size: 18px;
    text-align: right;
    padding-right: 40px;
    display: block;
    padding-top: 8px;
    cursor:pointer;

    &:after{
      position: absolute;
      top: 15px;
      right: 9px;
      width: 22px;
      height: 12px;
      content: " ";
      pointer-events: none;
    }
  }
  .btn-file input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
  }

  h2{
    font-size:24px;
    color:$light-red-color;
    font-weight: bold;
    margin-bottom:0;
    margin-top:0;
  }

  span.small-note{
    color:$light-red-color;
    font-size:16px;
  }

  p.note{
    color:#e2d6c6;
    font-size:17px;
    margin-bottom:30px;
    font-weight: lighter;
  }

  div.form-head-spacer{
    width:100%;
    height:25px;
  }

  hr.form-hr{
    margin:0px;
    margin-top:13px;
    margin-bottom:30px;
    border:0;
    height:1px;
    width:100%;
    background-color:#d2d2d2;
  }


  .form-control{
    border:1px solid #e2d6c6;
    width:100%;
    height:52px;
    border-radius: 0px;
    color: #000;
    font-size:18px;
    @include placeholder {
      color: #777;
      font-size:18px;
    }
  }

  .form-group{
    margin-bottom:17px;
  }

  .has-error{
    .form-control{
      border:1px solid #a94442;
      @include placeholder {
        color: #a94442;
        font-size:18px;
      }
    }
  }

  textarea.form-control{
    min-height:100px;
    resize:none;
  }

  $activeColor: #f9ed00; //green
  $darkenColor: darken($activeColor, 20%);
  $background: #f9ed00;


  /* .customCheckbox */
  section{
    padding-top:10px;
  }
  .customCheckbox {
    width: 24px;
    height:24px;
    position: relative;
    float:right;
    margin-bottom:17px;
    label {
      width: 24px;
      height:24px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      border:1px solid #e2d6c6;
      @include transition(all 0.3s ease-in-out);
      &:after {
        content: '';
        width: 14px;
        height: 7px;
        position: absolute;
        top: 5px;
        left: 4px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover {
        background-color:#000;
      }
      &:hover::after {
        opacity: 0.8;
      }
      &:focus{
        outline:0;
      }
    }

    &.force-checked label{
      background-color:#000;
    }
    &.force-checked label::after{
      opacity: 1 ;
    }

    input[type=checkbox] {
      visibility: hidden;
      margin: 0;
      width: 24px;
      height: 24px;
      &:checked + label {
        background-color:#000;
      }

      &:checked + label:after {
        opacity: 1;
      }
    }
  }

  .customCheckboxText{
    float:right;
    font-size:15px;
    color:#000;
    margin-right:9px;
    margin-top:1px;
    max-width: 85%;
  }

  .btn-form{
    border-radius: 0px;
    color:#fff;
    font-size:20px;
    height:52px;
    width:100%;
  }
  .select-wrapper{
    position: relative;
    select{
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }
    &:after{
      position: absolute;
      top: 9px;
      left: 9px;
      width: 27px;
      height: 27px;
      content: " ";
      pointer-events: none;
    }
  }

  .date-box{
    position: relative;
    cursor:pointer;

    &:after{
      position: absolute;
      top: 9px;
      left: 9px;
      width: 27px;
      height: 27px;
      content: " ";
      pointer-events: none;
    }
  }

}

.form-bg-holder{
  padding:0px 20px;
}

.form-circle-note{
  width: 240px;
  height: 240px;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto auto;
  top: 170px;
  display: inline-block;
  border-radius: 50%;
  background-color: #f9ed00;
  border: 4px solid #fff;
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.2);
  padding: 43px 0px;
  color: #fff;
  @include respond-to('small') {
    margin:0 auto;
    position: relative;
    float:none;
    top:0;left:0;right:0;bottom:0;
    display:block;
    margin-bottom:20px;
  }
  .headline{
    font-size:24px;
    margin-bottom: 10px;
  }
  .text{
    font-size:16px;
    a{
      color:#000;
      &:focus,&:hover{
        color:#000;
      }
    }
  }
}


#map{
  width:100%;
  max-width:747px;
  height: 517px;
  border: 3px solid #aa8533
}

.custom-form-wrapper{
  width:100%;
  height: 517px;
  border: 3px solid #aa8533;
  padding:44px 34px;
}
