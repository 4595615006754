 .wrap-box-img-project{
   max-width: 360px;
   height: 360px;
   position:relative;
   margin-bottom: 60px;
   border: 1px solid #e2d7c5;
   &:before{
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     border: 1px solid #fff;
     pointer-events: none;
     z-index: 1;
   }
   &:hover{
     cursor: pointer;
    & .box-image-project{
       & > div > a{
         & > img{
           transform: scale(1.05);
           -webkit-transform: scale(1.05);
           -moz-transform: scale(1.05);
         }
       }
       &:after{
         content: "";
         position: absolute;
         width: 300px;
         height: 300px;
         top: 50%;
         left: 50%;
         -webkit-transform: translate(-50% ,-50%);
         -moz-transform: translate(-50% ,-50%);
         transform: translate(-50% ,-50%);
         background: rgba(255,255,255,0.75);
         -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.2);
         -moz-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.2);
         box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.2);
         pointer-events: none;
       }
       &:before{
         content: "";
         position: absolute;
         background: url('../images/plus.png');
         width: 71px;
         height: 71px;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         -webkit-transform: translate(-50%, -50%);
         -moz-transform: translate(-50%, -50%);
         z-index: 9;
         pointer-events: none;
       }
     }
   }
 }

.box-image-project{
  position: relative;
  overflow: hidden;
  height: 100%;
  & > div{
    height: 100%;
    & > a{
      & > img{
        @include transition(all .7s ease);
      }
    }
  }
}

.box-image-discription{
  padding: 15px;
  height: 100%;
  & > p{
    font-size: 22px;
    color: #aa8533;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    padding: 0 5px;
  }
}

/*responsive*/

@include respond-to('small'){
  .wrap-box-img-project{
    margin-left: auto;
    margin-right: auto;
  }
}
