.box-project{
  margin-bottom: 60px;
  display: block;
  max-width: 360px;
  height: 360px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 );
  -webkit-box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  position: relative;
  @include transition(all .5s ease);
  &:hover{
    -webkit-box-shadow: 0px 0px 40px 10px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 40px 10px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 40px 10px rgba(0,0,0,0.1);
    .project-wrap-img{
      & > div > img{
        filter: none;
        -webkit-filter: grayscale(0%);
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
      }
    }
  }
  @include respond-to('small'){
    .project-wrap-img{
      & > div > img{
        filter: none;
        -webkit-filter: grayscale(0%);
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
      }
    }
  }
}

.project-wrap-img{
  padding: 20px 20px 0 20px;
  & > div{
    overflow: hidden;
    & > img{
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+, IE10 */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
      @include transition(all .6s ease); /* Fade to color for Chrome and Safari */
      -webkit-backface-visibility: hidden; /* Fix for transition flickering */

    }
  }
}

.project-wrap-txt{
  text-align: center;
}

.project-name{
  font-size: 26px;
  font-weight: 300;
  color: #000;
  border-bottom: 1px solid #e2d7c5;
  padding-bottom: 10px;
  margin-bottom:4px;
  display: inline-block;
  @include respond-to('small'){
    font-size: 22px;
  }
}

.project-city{
  font-size: 18px;
  font-weight: 300;
  color: #aa8533;
  @include respond-to('small'){
    font-size: 17px;
  }
}
