.wrap-bg-content-header{
  position: relative;
  width: 100%;
  min-height: 500px;
}

.bg-content-header{
  background-position: center center;
  background-size: cover;
  height: 500px;
  -webkit-box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.2);
  box-shadow: inset 0px -10px 20px 0px rgba(0,0,0,0.2);
}

.wrap-bg-content-header__mobile{
  @include respond-to('extra-small'){
    min-height: 310px;
  }
}

.bg-content-header__mobile{
  @include respond-to('extra-small'){
    height: 310px;
  }
}

.content-header{
  text-align: center;
  & > h1{
    font-size: 30px;
    font-weight: 300;
    color: #aa8533;
    margin: 0;
    margin-top: 30px;
  }
  & > hr{
    border-top: 1px solid #e2d7c5;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.wrap-content-txt{
  & > div{
    & > p{
      font-size: 18px;
      font-weight: 400;
      color: #000;
      margin: 0;
    }
  }
  & .slogan{
    margin-right: 65px;
  }
}

.wrap-content-txt .content_box {
    float: right;
    margin: 10px 1%;
    width: 23%;
    font-size: 18px;
    text-align: center;
    min-width: 275px;
    @include respond-to('medium'){
      float: none;
      margin: 10px auto;
    }
}
.wrap-content-txt .content_box h3 {
    text-align: center;
}
.wrap-content-txt .content_box img {
    box-shadow: 0px 0px 5px -3px #333;
    margin-bottom: 10px;
}


/*responsive*/

@include respond-to('extra-small'){
  .wrap-content-txt .slogan{
    margin-right: 0;
  }
  .wrap-content-txt>div>p{
    font-size: 16px;
  }
}
