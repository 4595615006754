.gradient{
  background: #ccbf7a;
  background: -moz-linear-gradient(top, #ccbf7a 0%, #aa8533 100%);
  background: -webkit-linear-gradient(top, #ccbf7a 0%,#aa8533 100%);
  background: linear-gradient(to bottom, #ccbf7a 0%,#aa8533 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccbf7a', endColorstr='#aa8533',GradientType=0 );
  & > div{
    span,p{
      color:#fff !important;
    }
  }
}

.hp-content{
  & > div{
    & > p{
      font-size: 18px;
      font-weight: 400;
      color: #000;
      margin-bottom: 5px;
    }
  }
  & > a{
    font-size: 18px;
    font-weight: 400;
    color: #aa8533;
    border-top: 1px solid #e2d7c5;
    padding-top: 10px;
    display: inline-block;
    margin-top: 35px;
    transition: all .4s ease;
    & > i{
      font-size: 13px;
      margin-right: 5px;
      @include transition(all .4s ease);
    }
    &:hover{
      color: #6c4a00;
      border-top-color: #6c4a00;
      & > i{
        transform: translateX(-5px);
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
      }
    }
  }
}

.hp-businessCart{
  & > img{
    margin-top: -70px;
    margin-right: -50px;
    width: 436px;
    position: absolute;
    z-index: 1;
  }
}

.hp-wrap-projectBest{
  text-align: center;
  & > hr{
    border-top: 1px solid #e2d7c5;
    margin-bottom: 40px;
    margin-top:50px;
    &:last-child{
      margin-top: 40px;
    }
  }
  & > h1{
    font-size: 30px;
    font-weight: 300;
    color: #aa8533;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 0;
  }
  & > a{
    font-size: 18px;
    font-weight: 400;
    color: #aa8533;
    border-top: 1px solid #e2d7c5;
    padding-top: 7px;
    display: inline-block;
    transition: all .4s ease;
    @include respond-to('small'){
      margin-bottom: 15px;
    }
    & > i{
      font-size: 13px;
      margin-right: 5px;
      @include transition(all .4s ease);
    }
    &:hover{
      color: #6c4a00;
      border-top-color: #6c4a00;
      & > i{
        transform: translateX(-5px);
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
      }
    }
  }
}

/*responsive*/

@media(max-width:1500px){
  .icon-scroll{
    bottom:200px;
  }
}

@media(max-width:1200px){
  .icon-scroll{
    bottom:400px;
  }
}

@include respond-to('small'){
  .bgshadow-content{
    transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
    & > div{
      & > p{
        font-size: 35px;
      }
    }
  }
  .icon-scroll{
    display: none;
  }
  .box-project{
    margin-left: auto;
    margin-right: auto;
    max-height: 360px;
    height: inherit;
    padding-bottom: 15px;
  }
}

@include respond-to('medium'){
  .hp-businessCart{
    display: none;
  }
}
