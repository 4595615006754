.project-box-txt{
  position: relative;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 );
  -webkit-box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 40px 5px rgba(0,0,0,0.1);
  padding: 35px 40px 20px 40px;
  & > a{
    font-size: 16px;
    font-weight: 300;
    color: #aa8533;
    border-top: 1px solid #e2d7c5;
    padding-top: 10px;
    display: inline-block;
    margin-top: 2px;
    transition: all .4s ease;
    & > i{
      font-size: 13px;
      margin-right: 5px;
      @include transition(all .4s ease);
    }
    &:hover{
      color: #6c4a00;
      border-top-color: #6c4a00;
      & > i{
        transform: translateX(-5px);
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
      }
    }
  }
  & > div{
    height: 268px;
    overflow: hidden;
    @include respond-to('extra-small'){
      height: initial;
      overflow: initial;
    }
    & > p{
      margin:0;
      font-size: 18px;
      font-weight: 300;
      color: #6c4a00;
      line-height: 1.4;
    }
  }
}

.unlimited{
  width:100%;
  .project-box-txt{
    width:100%;
    margin-bottom:25px;
    & > div{
      height: auto;
    }
  }
}

/*responsive*/
@include respond-to('small'){
  .project-box-txt{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }

}
