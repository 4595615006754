 .projects-header{
   margin-top: 130px;
   text-align: center;
   @include respond-to('small'){
     margin-top: 100px;
   }
   & > h1{
     font-size: 30px;
     font-weight: 300;
     color: #aa8533;
     margin: 0;
     @include respond-to('small'){
       font-size: 25px;
     }
   }
   & > hr{
     border-top: 1px solid #e2d7c5;
     margin-top: 35px;
     margin-bottom: 50px;
     @include respond-to('small'){
       margin-top: 30px;
       margin-bottom: 35px;
     }
   }
 }
