.main-header{
  .header-mobile{
    display:none;
  }
}

.main-header{
  width:100%;
  z-index:1000;
  position: absolute;
  top: 0;
  transition: all .4s ease;
  height:100px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 );
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  @include respond-to('medium'){
    height:80px;
    position: fixed;
    background-color: #fff;
    .header-desktop{
      display: none;
    }
    .header-mobile{
      display:block;
      & > a{
        margin-right: 20px;
        margin-top: 20px;
        display: inline-block;
        & > img{
          width: 250px;
        }
      }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        background-color: #aa8533;
      }
      .hamburger{
        padding: 25px 20px;
      }
    }
  }
}

.sb-slidebar{
  background-color: #f5f5f5;
}

.sb-left .sb-menu{
  & li{
    border-bottom: 0;
    & a{
      color: #aa8533;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
  }
}

.header-desktop{
  transition: all .4s ease;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  & > nav{
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    & > ul{
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;
      & > li{
        float: right;
        position: relative;
        &:not(:last-child){
          margin-left: 30px;
        }
        &:last-child{
          & > a{
            &:before{
              border-left: 0;
              position: absolute;
            }
          }
        }
        & > a{
          font-size: 22px;
          color: #aa8533;
          font-weight: 300;
          display: block;
          position: relative;
          &:before{
            content: " ";
            position: absolute;
            top: 6px;
            left: -15px;
            color: #e2d7c5;
            height: 20px;
            border-left: 1px solid;
          }
          &:hover{
            color: #6c4a00;
          }
        }
      }
    }
  }
  .logo{
    float: right;
    margin-top: 24px;
    margin-left: 55px;
  }
}

#fixed-top.scrolling{
  height:80px;
  position: fixed;
  background-color: #fff;
  & .header-desktop{
    & > nav{
      & > ul{
        & > li{
          & > a{
          }
        }
      }
    }
    .logo{
      margin-top: 14px;
    }
  }
}

/*responisve*/

@media(max-width:1250px){
  .header-desktop{
    & > .logo{
      margin-left: 30px;
      & > a{
        & > img{
          width: 200px;
          margin-top: 7px;
        }
      }
    }
    & > nav{
      & > ul{
        & > li{
          &:not(:last-child){
            margin-left: 20px;
          }
          & > a{
            font-size: 20px;
            &:before{
              left: -10px;
            }
          }
        }
      }
    }
  }
}

@media(max-width:390px){
  .main-header{
    .header-mobile{
      & > a{
        & > img{
          width: 200px;
          margin-top: 7px;
        }
      }
    }
  }
}
