.wrapper-banners{
  position: relative;
  width: 100%;
  height: 950px;
  @include respond-to('small'){
    height: 500px;
  }
}

.wrap-banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include respond-to('small'){
    position: initial;
  }
}

.banner-bg{
  width: 100%;
  min-height: 950px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('small'){
    min-height: 500px;
  }
}

.banner-bgshadow{
  width: 380px;
  height: 100%;
  position: absolute;
  top: 0;
  margin-right: -40px;
  background-color: rgba(255,255,255,0.6);
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 50px 10px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 50px 10px rgba(0,0,0,0.25);
  @include respond-to('small'){
    display: none;
  }
}

.bgshadow-content{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  padding: 0 70px;
  @include respond-to('small'){
    margin-top: 160px;
  }
  & > div{
    margin-bottom: 40px;
    & > p{
      font-size: 40px;
      color: #947427;
      font-weight: 300;
      margin:0;
    }
  }
  & > a{
    font-size: 20px;
    color: #947427;
    border-top: 2px solid #947427;
    padding-top: 10px;
  }
}

/*mouse scroll*/
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}
.icon-scroll {
  cursor: pointer;
  width: 28px;
  height: 44px;
  bottom: 200px;
  //top: 50%;
  box-shadow: inset 0 0 0 1px #fff;
  border: 1px solid #fff;
  border-radius: 25px;
  background-color: rgba(0,0,0,0.2);
}
.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  top: 5px;
  border-radius: 4px;
}

body:not(.Flickerblocks) .icon-scroll:before {
  -webkit-animation-name: drop;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  -webkit-animation-name: drop;
  animation-name: drop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 28px;
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 28px;
    opacity: 0;
  }
}
