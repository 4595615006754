.form-header{
  margin-top: 130px;
  text-align: center;
  & > h1{
    font-size: 30px;
    font-weight: 300;
    color: #aa8533;
    margin: 0;
  }
  & > hr{
    border-top: 1px solid #e2d7c5;
    margin-top: 25px;
    margin-bottom: 40px;
  }
}

.wrap-info-map{
  & > div{
    & > p{
      font-size: 20px;
      font-weight: 400;
      color: #000;
      margin-bottom: 35px;
    }
  }
}

.wrap-map{
  max-width: 747px;
  height: 517px;
  border: 3px solid #aa8533;
}


.wrap-contact{
  & > div{
    & > p{
      font-size: 20px;
      font-weight: 300;
      color: #000;

    }
    & > span{
      & > p{
        font-size: 27px;
        font-weight: 300;
        color: #aa8533;
      }
    }
  }
}
